import React, { useEffect } from "react";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TagsBlock } from "../TagsBlock";
import styles from "./Post.module.scss";
import { PostSkeleton } from "./Skeleton";
import { fetchRemovePost, fetchTags } from "../../redux/slices/post";

export const Post = ({
  _id,
  title,
  createdAt,
  imageUrl,
  viewsCount,
  tags,
  children,
  isFullPost,
  isLoading,
  isEditable,
  slug,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  const stateTags = useSelector((state) => state.posts.tags);
  const isTagsLoading = stateTags.status === "loading";

  if (isLoading) {
    return <PostSkeleton />;
  }

  // Функция для форматирования даты
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    // Пример формата: 31.12.2023 15:30
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} `;
  };

  const onClickRemove = () => {
    if (window.confirm("Remove?")) {
      dispatch(fetchRemovePost(slug));
    }
  };

  return (
    <main
      className={!isFullPost ? `${styles.notfullpost}` : `${styles.fullpost}`}
    >
      {isEditable && (
        <div className={styles.editButtons}>
          <Link to={`/posts/${slug}/edit`}>
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton onClick={onClickRemove} color="secondary">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      {isFullPost ? (
        <div className={styles.tagsBlock}>
          <TagsBlock />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.wrapp}>
        {imageUrl && (
          <div className={styles.imageWrapp}>
            <img
              className={clsx(styles.image, { [styles.imageFull]: isFullPost })}
              src={imageUrl}
              alt={title}
            />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.indention}>
            <p className={styles.data}>{formatCreatedAt(createdAt)}</p>
            <div>
              <h1
                className={clsx(styles.title, {
                  [styles.titleFull]: isFullPost,
                })}
              >
                {title}
              </h1>
              <ul className={styles.tags}>
                {tags.map((name) => (
                  <li key={name}>
                    <Link>#{name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            {isFullPost ? (
              <></>
            ) : (
              <Link to={`/posts/${slug}`} className={styles.link}>
                {t("post.readMore")}
              </Link>
            )}
            {children && <div className={styles.text}>{children}</div>}
            <div className={styles.postDetails}>
              <EyeIcon />
              <span>{viewsCount}</span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
