import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Seccess.module.scss";
import { MyButton } from "../MyButton";

export function Seccess({ isNotFullPay, link, payId }) {
  const { t } = useTranslation();
  return (
    <main
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h2 className={styles.thx}>
        {isNotFullPay ? t("seccess.notFullPay") : t("seccess.thx")}
        {payId && (
          <>
            <br />
            Payment id : {payId}
          </>
        )}
      </h2>
      <Link to={isNotFullPay ? `${link}` : "/me"}>
        <MyButton variant="dark">
          {isNotFullPay ? t("seccess.payBack") : t("seccess.back")}
        </MyButton>
      </Link>
    </main>
  );
}
