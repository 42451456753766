import React from "react";
import { useTranslation } from "react-i18next";

import Catalog from "../../components/Catalog";
import Banner from "../../components/Banner";
import Benefits from "../../components/Benefits";
import HowItWork from "../../components/HowItWork";
import Faq from "../../components/Faq";
import MetaTags from "../../components/MetaTags";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <main>
      <MetaTags
        title={t("metaTagsConfig.home.title")}
        description={t("metaTagsConfig.home.description")}
      />
      <Banner />
      <Catalog isAllCatalog={true} />
      <Benefits />
      <HowItWork />
      <Faq isFull={false} />
    </main>
  );
};
