import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import styles from "./Admin.module.scss";

export const AdminSkeleton = () => {
  return (
    <main className={styles.skeleton}>
      <Stack spacing={1} className={styles.skeletonWrapp}>
        <Skeleton variant="rectangular" width="100%" height={50} />
        <div className={styles.skeletonContent}>
          <div className={styles.skeletonInfo}>
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
            <Skeleton variant="text" width="100%" height={45} />
          </div>
        </div>
      </Stack>
    </main>
  );
};
