import React from "react";
import styles from "./MyButton.module.scss";

export function MyButton({ variant, children, onClick, fullWidth, className }) {
  const buttonClass = `${styles.button} ${styles[variant] || ""} ${
    fullWidth ? styles.fullWidth : ""
  } ${className || ""}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
}
