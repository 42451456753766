import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import styles from "./Product.module.scss";

export const ProductSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <Stack spacing={1}>
        <div className={styles.skeletonContent}>
          <Skeleton variant="text" width="80%" height={80} />
          <Skeleton variant="rectangular" width="100%" height={300} />

          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />

          <Skeleton variant="text" width="70%" height={65} />
        </div>
      </Stack>
    </div>
  );
};
