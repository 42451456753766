import { configureStore } from "@reduxjs/toolkit";
import { postReducer } from "./slices/post";
import { authReducer } from "./slices/auth";
import { productReducer } from "./slices/product";
import { userReducer } from "./slices/user";
import { orderReducer } from "./slices/order";

const store = configureStore({
  reducer: {
    posts: postReducer,
    auth: authReducer,
    products: productReducer,
    users: userReducer,
    orders: orderReducer,
  },
});

export default store;
