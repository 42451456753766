import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SimpleMDE from "react-simplemde-editor";
import { useSelector } from "react-redux";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import "easymde/dist/easymde.min.css";

import styles from "./AddPost.module.scss";
import { selectIsAdmin, selectIsAuth } from "../../redux/slices/auth";
import axios from "../../axios";

export const AddPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const isAuth = useSelector(selectIsAuth);
  const isAdmin = useSelector(selectIsAdmin);

  const [titleUk, setTitleUk] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [titleRu, setTitleRu] = useState("");

  const [textUk, setTextUk] = useState("");
  const [textEn, setTextEn] = useState("");
  const [textRu, setTextRu] = useState("");

  const [tagsUk, setTagsUk] = useState("");
  const [tagsEn, setTagsEn] = useState("");
  const [tagsRu, setTagsRu] = useState("");
  const [tagsSlug, setTagsSlug] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [slugPost, setSlugPost] = useState("");

  const inputFileRef = useRef(null);

  const isEditing = Boolean(slug);

  useEffect(() => {
    if (slug) {
      axios
        .get(`/posts/${slug}`)
        .then((data) => {
          setImageUrl(data.data.imageUrl);
          setSlugPost(data.data.slug);
          setTagsSlug(data.data.tags.slugTag.join(","));

          setTitleUk(data.data.title.uk);
          setTextUk(data.data.text.uk);
          setTagsUk(data.data.tags.uk.join(","));

          setTitleEn(data.data.title.en);
          setTextEn(data.data.text.en);
          setTagsEn(data.data.tags.en.join(","));

          setTitleRu(data.data.title.ru);
          setTextRu(data.data.text.ru);
          setTagsRu(data.data.tags.ru.join(","));
        })
        .catch((err) => {
          console.log(err);
          alert("Error edit");
        });
    }
  }, []);

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);

      const { data } = await axios.post("/upload", formData);
      setImageUrl(data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRemoveImage = () => {
    setImageUrl("");
  };

  const onChangeTextUk = useCallback((value) => {
    setTextUk(value);
  }, []);

  const onChangeTextEn = useCallback((value) => {
    setTextEn(value);
  }, []);

  const onChangeTextRu = useCallback((value) => {
    setTextRu(value);
  }, []);

  const onSubmit = async () => {
    try {
      const fields = {
        title: { uk: titleUk, en: titleEn, ru: titleRu },
        imageUrl,
        tags: {
          uk: tagsUk.split(","),
          en: tagsEn.split(","),
          ru: tagsRu.split(","),
          slugTag: tagsSlug.split(","),
        },
        slug: slugPost,
        text: { uk: textUk, en: textEn, ru: textRu },
      };
      const { data } = isEditing
        ? await axios.patch(`/posts/${slug}`, fields)
        : await axios.post("/posts", fields);

      const _slug = isEditing ? slug : data.slug;

      navigate(`/posts/${_slug}`);
    } catch (error) {
      console.log(error);
      alert(
        isEditing
          ? "Ошибка при редактирования статьи"
          : "Ошибка при создании статьи"
      );
    }
  };

  const options = useMemo(
    () => ({
      spellChecker: false,
      maxHeight: "400px",
      autofocus: true,
      status: false,
      autosave: {
        enabled: true,
        delay: 1000,
      },
    }),
    []
  );

  if (!window.localStorage.getItem("token") && !isAuth) {
    return <Navigate to="/" />;
  }

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <main>
      <h1 className="title">
        {isEditing ? "Редагувати пост" : "Створити пост"}
      </h1>
      <Paper>
        <Button
          onClick={() => {
            inputFileRef.current.click();
          }}
          variant="outlined"
          size="large"
        >
          Загрузить превью
        </Button>
        <input
          ref={inputFileRef}
          type="file"
          onChange={handleChangeFile}
          hidden
        />
        {imageUrl && (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={onClickRemoveImage}
            >
              Удалить
            </Button>
            <img
              className={styles.image}
              src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
              alt="Uploaded"
            />
          </>
        )}
        <br />
        <br />
        <TextField
          value={titleUk}
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Заголовок посту (укр)"
          fullWidth
          onChange={(e) => setTitleUk(e.target.value)}
        />
        <TextField
          value={titleEn}
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Заголовок посту (англ)"
          fullWidth
          onChange={(e) => setTitleEn(e.target.value)}
        />
        <TextField
          value={titleRu}
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Заголовок посту (русс)"
          fullWidth
          onChange={(e) => setTitleRu(e.target.value)}
        />
        <br /> <br />
        <TextField
          value={slugPost}
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Slug post"
          fullWidth
          onChange={(e) => setSlugPost(e.target.value)}
        />
        <br /> <br />
        <TextField
          value={tagsUk}
          classes={{ root: styles.tags }}
          variant="standard"
          fullWidth
          placeholder="Теги (укр)"
          onChange={(e) => setTagsUk(e.target.value)}
        />
        <TextField
          value={tagsEn}
          classes={{ root: styles.tags }}
          variant="standard"
          fullWidth
          placeholder="Теги (англ)"
          onChange={(e) => setTagsEn(e.target.value)}
        />
        <TextField
          value={tagsRu}
          classes={{ root: styles.tags }}
          variant="standard"
          fullWidth
          placeholder="Теги (русс)"
          onChange={(e) => setTagsRu(e.target.value)}
        />
        <TextField
          value={tagsSlug}
          classes={{ root: styles.tags }}
          variant="standard"
          fullWidth
          placeholder="Slug tags"
          onChange={(e) => setTagsSlug(e.target.value)}
        />
        <br /> <br />
        <SimpleMDE
          className={styles.editor}
          value={textUk}
          onChange={onChangeTextUk}
          options={options}
          placeholder="Текст посту (укр)"
        />
        <SimpleMDE
          className={styles.editor}
          value={textEn}
          onChange={onChangeTextEn}
          options={options}
          placeholder="Текст посту (англ)"
        />
        <SimpleMDE
          className={styles.editor}
          value={textRu}
          onChange={onChangeTextRu}
          options={options}
          placeholder="Текст посту (русс)"
        />
        <div className={styles.buttons}>
          <Button onClick={onSubmit} size="large" variant="contained">
            {isEditing ? "Сохранить" : "Опубликовать"}
          </Button>
          <a href="/">
            <Button size="large">Отмена</Button>
          </a>
        </div>
      </Paper>
    </main>
  );
};
