import React from "react";
import { NotFound } from "../components/NotFound";

export function NotFoundPage() {
  return (
    <main>
      <NotFound />
    </main>
  );
}
