import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../Me/Me.module.scss";
import Navbar from "../../components/Navbar";
import axios from "../../axios";
import { MyButton } from "../../components/MyButton";
import icon from "../../assets/images/orders-icon.svg";
import { selectIsAdmin } from "../../redux/slices/auth";

export const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isAdmin = useSelector(selectIsAdmin);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersResponse = await axios.get(`/orders/user/${id}`);
        setOrders(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching user orders:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUser = async () => {
      try {
        const userResponse = await axios.get(`/auth/me`);
        const currentUserId = userResponse.data._id;

        if (isAdmin || id === currentUserId) {
          fetchData();
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (window.localStorage.getItem("token")) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [id, isAdmin, navigate]);

  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  // function getMonthDifference(date1, date2) {
  //   var year1 = date1.getFullYear();
  //   var year2 = date2.getFullYear();
  //   var month1 = date1.getMonth();
  //   var month2 = date2.getMonth();

  //   var yearDifference = year2 - year1;
  //   var monthDifference = month2 - month1;

  //   return yearDifference * 12 + monthDifference;
  // }

  function getMonthDifference(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const minutesDifference = Math.ceil(diffInMilliseconds / (1000 * 60));
    return minutesDifference;
  }

  return (
    <main>
      <div className={styles.wrapp}>
        {isAdmin ? null : (
          <div className={`${styles.item} ${styles.navbar}`}>
            <Navbar />
          </div>
        )}
        <div className={styles.item}>
          {loading ? (
            <p>Loading orders...</p>
          ) : orders.length === 0 ? (
            <p>No orders available.</p>
          ) : (
            <>
              <h2 className={styles.title}>
                <img src={icon} alt="" className={styles.icon} />{" "}
                {isAdmin
                  ? t("orders.clientServices", {
                      email: orders[0]?.user?.email,
                    })
                  : t("orders.myServices")}
              </h2>
              <TableContainer component={Paper} className={styles.table}>
                <Table sx={{ minWidth: 650 }} aria-label="order table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("orders.services")}</TableCell>
                      <TableCell>{t("orders.data")}</TableCell>
                      <TableCell>{t("orders.status")}</TableCell>
                      <TableCell>{t("orders.all")}</TableCell>
                      <TableCell>{t("orders.diy")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow
                        key={order._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{order.product.title}</TableCell>
                        <TableCell>
                          {new Date(order.availableTo).toLocaleDateString()}
                        </TableCell>
                        <TableCell
                          style={
                            order.status === "Success"
                              ? { color: "#00B528" }
                              : order.status === "Part pay"
                              ? { color: "#edff4b" }
                              : order.status === "Wait for access"
                              ? { color: "#7400D1" }
                              : order.status === "Finished"
                              ? { color: "#F10E00" }
                              : {}
                          }
                        >
                          {order.status}
                        </TableCell>
                        <TableCell>
                          {order.product.price *
                            getMonthDifference(
                              new Date(order.createdAt),
                              new Date(order.availableTo)
                            )}
                          ${" "}
                        </TableCell>
                        <TableCell>
                          <Link to={`/order/${order._id}`}>
                            <MyButton variant="dark">
                              {t("orders.more")}
                            </MyButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </main>
  );
};
