import React from "react";
import { useTranslation } from "react-i18next";

export function OfferContract() {
  const { t } = useTranslation();
  return (
    <main>
      <h1 className="title"> {t("offerContract.title")} </h1>
      <p
        className="text textBlock"
        style={{ lineHeight: "1.6 !important" }}
        dangerouslySetInnerHTML={{ __html: t("offerContract.text") }}
      />
    </main>
  );
}
