import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import styles from "./Product.module.scss";

export const ProductSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <Stack spacing={1}>
        <div className={styles.skeletonContent}>
          <div className={styles.skeletonItem}>
            <Skeleton variant="rectangular" width="100%" height={500} />
          </div>
          <div className={styles.skeletonItem}>
            <Skeleton variant="text" width="70%" height={65} />
            <Skeleton variant="text" width="50%" height={30} />
            <Skeleton variant="text" width="50%" height={30} />
            <Skeleton variant="text" width="50%" height={30} />
            <div className={styles.skeletonBtns}>
              <div className={styles.skeletonBtn}>
                <Skeleton variant="text" width="100%" height={45} />
              </div>
              <div className={styles.skeletonBtn}>
                <Skeleton variant="text" width="100%" height={45} />
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </div>
  );
};
