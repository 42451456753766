import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SimpleMDE from "react-simplemde-editor";
import { useSelector } from "react-redux";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import "easymde/dist/easymde.min.css";

import axios from "../../axios";
import { selectIsAdmin } from "../../redux/slices/auth";
import styles from "./AddPost.module.scss";

export const AddProduct = () => {
  const { slug } = useParams();

  const isEditing = Boolean(slug);
  const isAdmin = useSelector(selectIsAdmin);

  const navigate = useNavigate();

  // Ensure all hooks are declared at the top level
  const [title, setTitle] = useState("");
  const [squ, setSqu] = useState("");
  const [textUk, setTextUk] = useState("");
  const [textEn, setTextEn] = useState("");
  const [textRu, setTextRu] = useState("");

  const [metaTitleUk, setMetaTitleUk] = useState("");
  const [metaTitleEn, setMetaTitleEn] = useState("");
  const [metaTitleRu, setMetaTitleRu] = useState("");

  const [metaTextUk, setMetaTextUk] = useState("");
  const [metaTextEn, setMetaTextEn] = useState("");
  const [metaTextRu, setMetaTextRu] = useState("");

  const [option, setOption] = useState("");
  const [textSlug, setTextSlug] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);
  const inputFileRef = useRef(null);

  console.log(isAdmin);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
  }, [isAdmin]);

  useEffect(() => {
    if (slug) {
      axios
        .get(`/products/${slug}`)
        .then((data) => {
          console.log(data);
          setTitle(data.data.title || "");
          setTextUk(data.data.text.uk || "");
          setTextEn(data.data.text.en || "");
          setTextRu(data.data.text.ru || "");

          setMetaTitleUk(data.data.metaTitle.uk || "");
          setMetaTitleEn(data.data.metaTitle.en || "");
          setMetaTitleRu(data.data.metaTitle.ru || "");

          setMetaTextUk(data.data.metaDescription.uk || "");
          setMetaTextEn(data.data.metaDescription.en || "");
          setMetaTextRu(data.data.metaDescription.ru || "");

          setSqu(data.data.squ || "");
          setOption(data.data.options || "");
          setTextSlug(data.data.slug || "");
          setImageUrl(data.data.imageUrl || "");
          setPrice(data.data.price || "");
          setQuantity(data.data.quantity || "");
        })
        .catch((err) => {
          console.log(err);
          alert("Error edit product (GET)");
        });
    }
  }, [slug]);

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setImageUrl(data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRemoveImage = () => {
    setImageUrl("");
  };

  const onChangeTextUk = useCallback((value) => {
    setTextUk(value);
  }, []);

  const onChangeTextEn = useCallback((value) => {
    setTextEn(value);
  }, []);

  const onChangeTextRu = useCallback((value) => {
    setTextRu(value);
  }, []);

  const onChangeOption = useCallback((value) => {
    setOption(value);
  }, []);

  const onChangeSlug = useCallback((value) => {
    setTextSlug(value);
  }, []);

  const onSubmit = async () => {
    try {
      const fields = {
        title,
        imageUrl,
        price,
        quantity,
        options: option,
        slug: textSlug,
        squ,
        text: { uk: textUk, en: textEn, ru: textRu },
        metaTitle: { uk: metaTitleUk, en: metaTitleEn, ru: metaTitleRu },
        metaDescription: { uk: metaTextUk, en: metaTextEn, ru: metaTextRu },
      };

      const { data } = isEditing
        ? await axios.patch(`/products/${slug}`, fields)
        : await axios.post("/products", fields);

      const _slug = isEditing ? slug : data.slug;

      navigate(`/products/${_slug}`);
    } catch (error) {
      console.log(error);
      alert(
        isEditing
          ? "Ошибка при редактирования товара"
          : "Ошибка при создании товара"
      );
    }
  };

  const options = useMemo(
    () => ({
      spellChecker: false,
      maxHeight: "200px",
      autofocus: true,
      status: false,
      autosave: {
        enabled: true,
        delay: 1000,
      },
    }),
    []
  );

  const optionsList = useMemo(
    () => ({
      spellChecker: false,
      maxHeight: "100px",
      autofocus: true,
      placeholder: "Список характеристик",
      status: false,
      autosave: {
        enabled: true,
        delay: 1000,
      },
    }),
    []
  );

  return (
    <main>
      <h1 className="title">
        {isEditing ? "Редагувати продукт" : "Створити продукт"}
      </h1>
      <Paper>
        <Button
          onClick={() => {
            inputFileRef.current.click();
          }}
          variant="outlined"
          size="large"
        >
          Загрузити превью
        </Button>
        <input
          ref={inputFileRef}
          type="file"
          onChange={handleChangeFile}
          hidden
        />
        {imageUrl && (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={onClickRemoveImage}
            >
              Удалить
            </Button>
            <img
              className={styles.image}
              src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
              alt="Uploaded"
            />
          </>
        )}

        <br />
        <br />
        <TextField
          value={title}
          variant="outlined"
          placeholder="Назва"
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          value={textSlug}
          variant="outlined"
          placeholder="Slug"
          fullWidth
          onChange={(e) => onChangeSlug(e.target.value)}
        />
        <TextField
          value={squ}
          variant="outlined"
          placeholder="SQU"
          fullWidth
          onChange={(e) => setSqu(e.target.value)}
        />
        <TextField
          value={quantity}
          variant="outlined"
          placeholder="Количество"
          fullWidth
          onChange={(e) => setQuantity(e.target.value)}
        />
        <TextField
          value={price}
          variant="outlined"
          placeholder="Ціна"
          fullWidth
          onChange={(e) => setPrice(e.target.value)}
        />
        <br />
        <br />
        <TextField
          value={metaTitleUk}
          variant="outlined"
          placeholder="Meta title UK"
          fullWidth
          onChange={(e) => setMetaTitleUk(e.target.value)}
        />
        <TextField
          value={metaTitleEn}
          variant="outlined"
          placeholder="Meta title EN"
          fullWidth
          onChange={(e) => setMetaTitleEn(e.target.value)}
        />
        <TextField
          value={metaTitleRu}
          variant="outlined"
          placeholder="Meta title RU"
          fullWidth
          onChange={(e) => setMetaTitleRu(e.target.value)}
        />
        <TextField
          value={metaTextUk}
          variant="outlined"
          placeholder="Meta description UK"
          fullWidth
          onChange={(e) => setMetaTextUk(e.target.value)}
        />
        <TextField
          value={metaTextEn}
          variant="outlined"
          placeholder="Meta description EN"
          fullWidth
          onChange={(e) => setMetaTextEn(e.target.value)}
        />
        <TextField
          value={metaTextRu}
          variant="outlined"
          placeholder="Meta description RU"
          fullWidth
          onChange={(e) => setMetaTextRu(e.target.value)}
        />

        <SimpleMDE
          className={styles.editor}
          value={textUk}
          onChange={onChangeTextUk}
          options={options}
          placeholder="Опис товару (укр)"
        />
        <SimpleMDE
          className={styles.editor}
          value={textEn}
          onChange={onChangeTextEn}
          options={options}
          placeholder="Опис товару (англ)"
        />
        <SimpleMDE
          className={styles.editor}
          value={textRu}
          onChange={onChangeTextRu}
          options={options}
          placeholder="Опис товару (русс)"
        />
        <SimpleMDE
          className={styles.editor}
          value={option}
          onChange={onChangeOption}
          options={optionsList}
        />
        <div className={styles.buttons}>
          <Button onClick={onSubmit} size="large" variant="contained">
            Опубликовать
          </Button>
          <a href="/">
            <Button size="large">Отмена</Button>
          </a>
        </div>
      </Paper>
    </main>
  );
};
