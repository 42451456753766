import axios from "axios";

const instance = axios.create({
  baseURL: "/api",
  // baseURL: "http://192.168.0.103:4444/api",
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem("token");
  return config;
});

export default instance;
