import React from "react";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/about-logo.svg";
import image from "../../assets/images/about-image.png";
import styles from "./AboutBlock.module.scss";

export default function AboutBlock() {
  const { t } = useTranslation();
  return (
    <section>
      <img src={logo} alt="" className={styles.logo} />
      <div className={styles.content}>
        <div className={styles.item}>
          <h1 className="title">{t("aboutblock.title")}</h1>
          <p className={styles.text1}>{t("aboutblock.text1")}</p>
          <p className={styles.text2}>{t("aboutblock.text2")}</p>
        </div>
        <div className={styles.item}>
          <img src={image} alt="" />
        </div>
      </div>
      <p className={styles.text1}>{t("aboutblock.text3")}</p>
    </section>
  );
}
