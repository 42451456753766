import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Banner.module.scss";
import image1 from "../../assets/images/hero-1.png";
import image2 from "../../assets/images/hero-2.png";
import { MyButton } from "../MyButton";

export default function Banner() {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <div className={styles.item}>
        <img src={image1} alt="" />
        <div className={styles.content}>
          <h1 dangerouslySetInnerHTML={{ __html: t("banner.title") }} />
          <p>{t("banner.text1")}</p>
          <p style={{ marginTop: "10px" }}>{t("banner.text2")}</p>
          <div className={styles.btns}>
            <a href="#catalog">
              <MyButton variant="dark" className={styles.customButton}>
                {t("common.selectMac")}
              </MyButton>
            </a>
            <a href="#catalog">
              <MyButton variant="grey" className={styles.customButton}>
                {t("common.startServer")}
              </MyButton>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <img src={image2} alt="" />
      </div>
    </section>
  );
}
