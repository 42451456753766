import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Breadcrumbs, Typography } from "@mui/material";

import MetaTags from "./../components/MetaTags";
import Faq from "../components/Faq";
import Form from "../components/Form";

export const FaqPage = () => {
  const { t } = useTranslation();
  return (
    <main>
      <MetaTags
        title={t("metaTagsConfig.faq.title")}
        description={t("metaTagsConfig.faq.description")}
      />
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Typography color="text.primary">FAQs</Typography>
      </Breadcrumbs>
      <Faq isFull={true} />
      <Form title={t(`faq.title`)} text={t(`faq.text`)} />
    </main>
  );
};
