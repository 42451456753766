import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ContactBlock.module.scss";

export default function ContactBlock() {
  const { t } = useTranslation();
  return (
    <section className={`${styles.root}`}>
      <h1 className="title">{t("contactblock.title")}</h1>
      <div className={styles.wrapp}>
        {/* <div className={styles.item}>
          <p className={styles.text}>{t("common.phone")}:</p>
          <a href="tel:0674623883" className={styles.link}>
            (067) 462-38-83
          </a>
        </div> */}
        <div className={styles.item}>
          <p className={styles.text}>Email:</p>
          <a href="mailto:contact@macweb.com" className={styles.link}>
            contact@macweb.com
          </a>
        </div>
        <div className={styles.item}>
          <p className={styles.text}>{t("footer.address")}: </p>
          <p className={styles.link}>USA, California</p>
        </div>
      </div>
    </section>
  );
}
