import React from "react";
import ContactBlock from "../components/ContactBlock";
import Form from "../components/Form";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import MetaTags from "./../components/MetaTags";
import { useTranslation } from "react-i18next";

export function Contact() {
  const { t } = useTranslation();
  return (
    <main>
      <MetaTags
        title={t("metaTagsConfig.contact.title")}
        description={t("metaTagsConfig.contact.description")}
      />
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
        <Link underline="hover" color="inherit" href="/">
          {t("common.home")}
        </Link>
        <Typography color="text.primary">{t("contact.contact")}</Typography>
      </Breadcrumbs>
      <ContactBlock />
      <Form
        isPadding={true}
        title={t(`contact.title`)}
        text={t(`contact.text`)}
      />
    </main>
  );
}
