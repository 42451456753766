import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import imageUrl from "../../assets/images/dropdownImage.png";
import styles from "./Dropdown.module.scss";
import Navigation from "../../components/Navigation";

export default function Dropdown({ menu, title, isProduct }) {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsActive(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={styles.root}>
      <p
        className={`${styles.btn} ${isActive ? styles.active : ""}`}
        onClick={toggleDropdown}
      >
        {title}
      </p>
      <div className={`${styles.dropdown} ${isActive ? styles.active : ""}`}>
        <div className={`${styles.menu__wrap} container`}>
          <Navigation menu={menu} margin={50} isProduct={isProduct} />
        </div>
        <img className={styles.image} src={imageUrl} alt="dropdown" />
      </div>
    </div>
  );
}
