import React from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import styles from "./Form.module.scss";
import { MyButton } from "../MyButton";

const sendEmail = async (email, name, tel, message) => {
  try {
    const response = await axios.post("/send-email", {
      email,
      name,
      message,
      tel,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to send email: " + error.message);
  }
};

export default function Form({ title, text, isPadding }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (values) => {
    try {
      const response = await sendEmail(
        values.email,
        values.name,
        values.message,
        values.tel
      );
      alert(response.message);
    } catch (error) {
      console.error("Failed to send email:", error);
      alert(error.message); // Выводим сообщение об ошибке
    }
  };

  return (
    <section
      className={isPadding ? `${styles.root} ${"section"}` : `${styles.root}`}
      id="form"
    >
      <div className={styles.wrapp}>
        <div className={styles.item}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.item}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputs}>
              <TextField
                type="name"
                className={styles.field}
                label={t("form.name")}
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                {...register("name", { required: t("form.enterName") })}
                fullWidth
              />
              <TextField
                type="tel"
                className={styles.field}
                label={t("common.phone")}
                error={Boolean(errors.tel?.message)}
                helperText={errors.tel?.message}
                {...register("tel", { required: t("form.enterPhone") })}
                fullWidth
              />
            </div>
            <TextField
              type="email"
              className={styles.field}
              label={t("form.email")}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
              {...register("email", { required: t("form.enterEmail") })}
              fullWidth
            />
            <TextField
              multiline
              rows={4}
              className={styles.field}
              label={t("form.mess")}
              {...register("message")}
              fullWidth
            />
            <div className={styles.submitButton}>
              <MyButton type="submit" variant="dark">
                {t("form.send")}
              </MyButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
