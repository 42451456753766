import React from "react";
import { useTranslation } from "react-i18next";

import Product from "../components/Product";
import Benefits from "../components/Benefits";
import HowItWork from "../components/HowItWork";
import Form from "../components/Form";
import Catalog from "../components/Catalog";

export const FullProduct = () => {
  const { t } = useTranslation();
  return (
    <main>
      <Product />
      <Benefits />
      <HowItWork />
      <Catalog isAllProduct={false} />
      <Form
        isPadding="true"
        title={t(`fullProduct.title`)}
        text={t(`fullProduct.text`)}
      />
    </main>
  );
};
