import { createTheme } from "@mui/material/styles";
import "@fontsource/montserrat"; // Ensure to install the package via npm or yarn
import "@fontsource/unbounded";

const defaultShadows = Array(25).fill("none");
export const theme = createTheme({
  shadows: defaultShadows,
  palette: {
    primary: {
      main: "#D2A0FA",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    allVariants: {
      color: "#181818",
      fontSize: "16px",
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          padding: "10px 47px",
          borderRadius: 7,
          boxShadow: "none",
          lineHeight: "normal",
        },
        containedPrimary: {
          backgroundColor: "#181818",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#343434",
          },
        },
        containedSecondary: {
          backgroundColor: "#F2F2F2",
          color: "#181818",
          "&:hover": {
            backgroundColor: "#E2E2E2",
          },
        },
      },
    },
  },
});
