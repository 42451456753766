import React, { useEffect } from "react";
import axios from "../../axios"; // Assumed to be configured correctly

export function Payment({ amount, orderId }) {
  useEffect(() => {
    const fetchPaymentForm = async () => {
      try {
        const response = await axios.post("/payment", { amount, orderId });
        // Insert the payment form received from the server
        document.getElementById("payment-form").innerHTML = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchPaymentForm();
  }, [amount, orderId]);

  return (
    <div>
      <div id="payment-form"></div>
    </div>
  );
}
