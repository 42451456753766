import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./HowItWork.module.scss";

export default function HowItWork() {
  const { t } = useTranslation();
  return (
    <section className={`${styles.root} ${"section"}`}>
      <h2 className="title">{t("howItWork.title")}</h2>
      <div className={styles.wrapp}>
        <div className={styles.item}>
          <p className={styles.number}> 01</p>
          <h3 className={styles.title}>{t("howItWork.subtitle1")}</h3>
          <p className={styles.text}>{t("howItWork.text1")}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.number}>02</p>
          <h3 className={styles.title}>{t("howItWork.subtitle2")}</h3>
          <p className={styles.text}>{t("howItWork.text2")}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.number}>03 </p>
          <h3 className={styles.title}>{t("howItWork.subtitle3")}</h3>
          <p className={styles.text}>{t("howItWork.text3")}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.number}>04 </p>
          <h3 className={styles.title}>{t("howItWork.subtitle4")}</h3>
          <p className={styles.text}>{t("howItWork.text4")}</p>
        </div>
      </div>
    </section>
  );
}
