import React, { useState, useRef, useEffect } from "react";

import styles from "./FaqItem.module.scss";

export const FaqItem = ({ title, text, initiallyOpen = false }) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const changeState = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <div
      onClick={changeState}
      className={`${styles.root} ${isOpen ? styles.active : ""}`}
    >
      <h2 className="subtitle">{title}</h2>
      <p
        ref={contentRef}
        className={styles.text}
        style={{
          height: isOpen ? `${height + 20}px` : "0px",
          overflow: "hidden",
        }}
      >
        {text}
      </p>
      <div className={styles.btn}>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
