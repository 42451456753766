import React from "react";
import { AccountCircleRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import styles from "./UserInfo.module.scss";

export const UserInfo = ({ email }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <span className={styles.title}>
          <AccountCircleRounded /> {email}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.text}>
          {t("userInfo.well")}, {email}
        </span>
        <span className={styles.text}>{t("userInfo.text")}</span>
      </div>
    </div>
  );
};
