import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Benefits from "../components/Benefits";
import AboutBlock from "../components/AboutBlock";
import MetaTags from "./../components/MetaTags";

export const About = () => {
  const { t } = useTranslation();
  return (
    <main>
      <MetaTags
        title={t("metaTagsConfig.about.title")}
        description={t("metaTagsConfig.about.description")}
      />
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
        <Link underline="hover" color="inherit" href="/">
          {t("common.home")}
        </Link>
        <Typography color="text.primary">{t("aboutblock.about")}</Typography>
      </Breadcrumbs>
      <AboutBlock />
      <Benefits />
    </main>
  );
};
