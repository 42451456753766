import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { fetchProducts } from "../../redux/slices/product";
import { selectIsAdmin } from "../../redux/slices/auth";
import styles from "./Catalog.module.scss";
import ProductItem from "../ProductItem";

export default function Catalog({ isAllCatalog }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useParams(); // Destructure to get the id parameter

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const products = useSelector((state) => state.products.products);

  const isLoading = products.status === "loading";
  const isError = products.status === "error";

  let displayedProducts = products.items;

  if (!isAllCatalog && id) {
    displayedProducts = products.items.filter((product) => product._id !== id);
  }

  const isAdmin = useSelector(selectIsAdmin);

  return (
    <section id="catalog" className="section">
      <div className={styles.titleWrap}>
        <h2 className={`${"title"} ${styles.title}`}>
          {isAllCatalog ? t("catalog.title1") : t("catalog.title2")}
        </h2>
        {isAllCatalog ? (
          <div className="greenBg">{t("common.notWirt")}</div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.wrapp}>
        {(isLoading ? [...Array(3)] : displayedProducts).map((obj, index) =>
          isLoading ? (
            <ProductItem isLoading={true} />
          ) : (
            <ProductItem
              key={obj._id}
              _id={obj._id}
              title={obj.title}
              imageUrl={
                obj.imageUrl
                  ? `${process.env.REACT_APP_API_URL}${obj.imageUrl}`
                  : ""
              }
              options={obj.options}
              price={obj.price}
              squ={obj.squ}
              isEditable={isAdmin}
              isSmall={!isAllCatalog}
              isLoading={false}
              slug={obj.slug}
            />
          )
        )}
        {isError && <p className={styles.error}>Failed to load products.</p>}
      </div>
    </section>
  );
}
