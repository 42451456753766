import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchOrder = createAsyncThunk(
  "order/fetchOrder",
  async (params) => {
    const { data } = await axios.post("/orders", params);
    return data;
  }
);

export const fetchRemoveOrder = createAsyncThunk(
  "order/fetchRemoveOrder",
  async (id) => {
    const { data } = await axios.delete(`/orders/${id}`);
    return data;
  }
);

const initialState = {
  order: null,
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    },
  },
  extraReducers: {
    [fetchOrder.pending]: (state) => {
      state.order = null;
    },
    [fetchOrder.fulfilled]: (state, action) => {
      state.order = action.payload;
    },
    [fetchOrder.rejected]: (state) => {
      state.order = null;
    },
  },
});

export const { setOrder } = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
