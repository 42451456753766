import React from "react";
import { Helmet } from "react-helmet";

import logo from "./../assets/images/mac_logo.svg";
import logotype from "./../assets/images/logo.svg";

const MetaTags = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={logotype} />
      <meta property="og:image:alt" content="Macosstrong" />
      <meta property="og:image:type" content="image/svg+xml" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Macosstrong" />
      <meta property="og:locale" content="ru_RU" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logotype} />
      <meta name="twitter:image:alt" content="Macosstrong" />

      <link rel="icon" type="image/x-icon" href={logo} />
    </Helmet>
  );
};

export default MetaTags;
