import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Navigate.module.scss";
import { fetchProducts } from "../../redux/slices/product";

export default function Navigation({ menu, margin, isProduct }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isProduct) {
      dispatch(fetchProducts());
    }
  }, [dispatch, isProduct]);

  const products = useSelector((state) => state.products.products.items);

  const renderMenuItems = () => {
    if (isProduct && products) {
      return products.map((product) => (
        <li className={styles.item}>
          <Link className={styles.link} to={`/products/${product.slug}`}>
            {product.title}
          </Link>
        </li>
      ));
    } else if (menu) {
      return Object.entries(menu).map(([label, url]) => (
        <li className={styles.item}>
          <Link className={styles.link} to={url}>
            {label}
          </Link>
        </li>
      ));
    }
    return null;
  };

  return (
    <ul className={styles.nav} style={{ gap: margin }}>
      {renderMenuItems()}
    </ul>
  );
}
