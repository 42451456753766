import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.svg";
import user from "../../assets/images/user.svg";
import mobileImage from "../../assets/images/dropdownImage.png";
import { selectIsAuth, logout, selectIsAdmin } from "../../redux/slices/auth";
import Dropdown from "../../components/Dropdown";
import Navigation from "../Navigation";
import Navbar from "../Navbar";
import { MyButton } from "../MyButton";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuth = useSelector(selectIsAuth);
  const isAdmin = useSelector(selectIsAdmin);

  const [isBurger, setIsBurger] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(null);

  const handleLogout = () => {
    if (window.confirm("Exit?")) {
      dispatch(logout());
      window.localStorage.removeItem("token");
      navigate("/");
    }
  };

  useEffect(() => {
    setIsBurger(true); // Закрыть бургер-меню при изменении маршрута
  }, [location]);

  const doBurger = () => {
    setIsBurger(!isBurger);
  };

  const getAuthButtons = () => {
    if (isAuth && isAdmin) {
      return (
        <>
          <Link key="admin-link" to="/admin">
            <MyButton variant="dark">{t("common.adminPanel")}</MyButton>
          </Link>
          <MyButton onClick={handleLogout} variant="grey">
            {t("common.exit")}
          </MyButton>
        </>
      );
    } else if (isAuth) {
      return (
        <>
          <MyButton variant="dark">
            <Link key="me-link" to="/me" className={styles.mybtn}>
              <img src={user} alt="user" className={styles.user} />
              {t("common.account")}
            </Link>
          </MyButton>
          <MyButton onClick={handleLogout} variant="grey">
            {t("common.exit")}
          </MyButton>
        </>
      );
    } else {
      return (
        <>
          <Link key="login-link" to="/login">
            <MyButton variant="dark">{t("common.login")}</MyButton>
          </Link>
          <Link key="register-link" to="/register">
            <MyButton variant="grey">{t("common.register")}</MyButton>
          </Link>
        </>
      );
    }
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    const path = location.pathname.split("/").filter(Boolean);
    if (path[0] === "uk" || path[0] === "ru") {
      path.shift();
    }
    if (lng === "uk") {
      path.unshift("uk");
    } else if (lng === "ru") {
      path.unshift("ru");
    }

    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);

    navigate(`/${path.join("/")}`);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 1) {
        setScrollDirection(true);
        setIsBurger(true);
      } else if (window.scrollY === 0) {
        setScrollDirection(false);
      } else {
        setScrollDirection(false);
      }

      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menu2 = {
    [t("header.contact")]: "/contacts",
    FAQ: "/faq",
    [t("header.blog")]: "/blog",
  };

  return (
    <header className={`${styles.root} ${scrollDirection ? "hide" : ""}`}>
      <div className="container">
        <div className={styles.inner}>
          <Link className={styles.logo} to="/">
            <img src={logo} alt="Logo" />
          </Link>
          {/* <a href="/checkout">card</a> */}
          <Dropdown menu={null} isProduct={true} title={t("header.services")} />
          <div className={styles.center}>
            <Link to={"/about-us"}> {t("header.about")} </Link>
          </div>
          <Dropdown menu={menu2} title={t("header.support")} />
          <div className={styles.left}>
            <div className={styles.buttons}>{getAuthButtons()}</div>

            <div className={styles.langWrapp}>
              <div
                className={styles.langItem}
                onClick={() => changeLanguage("en")}
              >
                en
              </div>
              <div
                className={styles.langItem}
                onClick={() => changeLanguage("uk")}
              >
                ua
              </div>
              <div
                className={styles.langItem}
                onClick={() => changeLanguage("ru")}
              >
                ru
              </div>
            </div>
            <button
              className={
                isBurger ? `${styles.menu}` : `${styles.menu} ${styles.opened}`
              }
              onClick={doBurger}
            >
              <svg width="40" height="40" viewBox="0 0 100 100">
                <path
                  className={`${styles.line} ${styles.line1}`}
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                ></path>
                <path
                  className={`${styles.line} ${styles.line1}`}
                  d="M 20,50 H 80"
                ></path>
                <path
                  className={`${styles.line} ${styles.line1}`}
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div
          className={
            isBurger ? `${styles.mobile}` : `${styles.mobile} ${styles.active}`
          }
        >
          <div className={`${styles.mobileInner} ${"container"}`}>
            {isAuth ? <Navbar /> : <></>}
            <Navigation menu={null} isProduct={true} margin={20} />
            <Navigation menu={menu2} margin={20} />
            <div className={styles.buttons}>{getAuthButtons()}</div>
            {isAuth ? (
              <></>
            ) : (
              <img src={mobileImage} alt="" className={styles.mobileImage} />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
