import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "../axios";
import { Post } from "../components/Post";
import MetaTags from "../components/MetaTags";

export const FullPost = () => {
  const { i18n } = useTranslation();
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/posts/${slug}`)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (isLoading) {
    return <Post isLoading={true} isFullPost />;
  }

  return (
    <>
      <MetaTags
        title={data.title[i18n.language]}
        description={
          data.text[i18n.language]
            .split(". ") // Разделяем текст на предложения
            .slice(0, 2) // Берем первые два предложения
            .join(". ") + "." // Объединяем их обратно в строку
        }
      />
      <Post
        _id={data._id}
        title={data.title[i18n.language]}
        imageUrl={
          data.imageUrl
            ? `${process.env.REACT_APP_API_URL}${data.imageUrl}`
            : ""
        }
        user={data.user}
        createdAt={data.createdAt}
        viewsCount={data.viewsCount}
        tags={data.tags[i18n.language]}
        slug={data.slug}
        isFullPost
      >
        <ReactMarkdown children={data.text[i18n.language]} />
      </Post>
    </>
  );
};
