import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../../axios";
import { UserInfo } from "../../components/UserInfo";
import Navbar from "../../components/Navbar";
import styles from "./Me.module.scss";
import { selectIsAdmin } from "../../redux/slices/auth";
import { useSelector } from "react-redux";

export const Me = () => {
  const [data, setData] = useState(null);
  const isAdmin = useSelector(selectIsAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`/auth/me`);
        setData(userResponse.data);
      } catch (error) {
        console.error("Error fetching user data or orders:", error);
      }
    };

    if (window.localStorage.getItem("token") && isAdmin) {
      navigate("/admin");
    } else if (window.localStorage.getItem("token")) {
      fetchData();
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <main>
      <div className={styles.wrapp}>
        <div className={`${styles.item} ${styles.navbar}`}>
          <Navbar />
        </div>
        <div className={styles.item}>
          <UserInfo {...data} />
        </div>
      </div>
    </main>
  );
};
