import React, { useEffect, useState } from "react";
import { MyButton } from "../MyButton";
import axios from "../../axios"; // Assumed to be configured correctly

export function CryptoPayment({ amount, orderId }) {
  const [link, setLink] = useState(null);
  const [isOk, setOk] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentForm = async () => {
      setLoading(true);
      try {
        const response = await axios.post("/paymentCrypto", {
          amount,
          orderId,
        });

        console.log(response);
        setLink(response.data.order.acquiring_url);
        setOk(true);
      } catch (error) {
        setOk(false);
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentForm();
  }, [amount, orderId]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : isOk ? (
        <MyButton variant="pink">
          <a href={link} style={{ color: "white" }}>
            Pay with Crypto
          </a>
        </MyButton>
      ) : (
        <p>Currently unavailable</p>
      )}
    </div>
  );
}
