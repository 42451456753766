import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header, Footer } from "./components";
import {
  Home,
  Blog,
  FullPost,
  Registration,
  AddPost,
  Login,
  FullProduct,
  AddProduct,
  Admin,
  Checkout,
  Order,
  Forgot,
  Me,
  FaqPage,
  About,
  Orders,
  Edit,
  NotFoundPage,
  Contact,
  Result,
  Policy,
  OfferContract,
} from "./pages";
import { fetchAuthMe } from "./redux/slices/auth";
import i18n from "./i18n";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("i18nextLng");
    const path = location.pathname.split("/").filter(Boolean);
    if (savedLanguage === "uk" || path[0] === "uk") {
      i18n.changeLanguage("uk");
    } else if (savedLanguage === "ru" || path[0] === "ru") {
      i18n.changeLanguage("ru");
    } else {
      i18n.changeLanguage("en");
    }

    // Если выбран укр язык и текущая страница не начинается с /uk, делаем редирект
    if (savedLanguage === "uk" && !location.pathname.startsWith("/uk")) {
      window.location.href = "/uk" + location.pathname;
    }

    if (savedLanguage === "ru" && !location.pathname.startsWith("/ru")) {
      window.location.href = "/ru" + location.pathname;
    }
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [location]);

  return (
    <>
      {loading ? (
        <div id="preloader"></div>
      ) : (
        <>
          <Header />
          <div className="container">
            <Routes>
              {/* Base routes (English) */}
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/tags/:slug" element={<Blog />} />
              <Route path="/posts/:slug" element={<FullPost />} />
              <Route path="/posts/:slug/edit" element={<AddPost />} />
              <Route path="/add-post" element={<AddPost />} />
              <Route path="/products/:slug" element={<FullProduct />} />
              <Route path="/products/:slug/edit" element={<AddProduct />} />
              <Route path="/add-product" element={<AddProduct />} />
              <Route path="/me" element={<Me />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/orders/:id" element={<Orders />} />
              <Route path="/order/:id" element={<Order />} />
              <Route path="/edit/:id" element={<Edit />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contacts" element={<Contact />} />
              <Route path="/privacy-policy" element={<Policy />} />
              <Route path="/offer-contract" element={<OfferContract />} />
              <Route path="/result/:orderId" element={<Result />} />

              {/* Маршрути для украинского */}
              <Route path="/uk/" element={<Home />} />
              <Route path="/uk/blog" element={<Blog />} />
              <Route path="/uk/tags/:slug" element={<Blog />} />
              <Route path="/uk/posts/:slug" element={<FullPost />} />
              <Route path="/uk/posts/:slug/edit" element={<AddPost />} />
              <Route path="/uk/add-post" element={<AddPost />} />
              <Route path="/uk/products/:slug" element={<FullProduct />} />
              <Route path="/uk/products/:slug/edit" element={<AddProduct />} />
              <Route path="/uk/add-product" element={<AddProduct />} />
              <Route path="/uk/me" element={<Me />} />
              <Route path="/uk/login" element={<Login />} />
              <Route path="/uk/register" element={<Registration />} />
              <Route path="/uk/admin" element={<Admin />} />
              <Route path="/uk/forgot" element={<Forgot />} />
              <Route path="/uk/checkout" element={<Checkout />} />
              <Route path="/uk/orders/:id" element={<Orders />} />
              <Route path="/uk/order/:id" element={<Order />} />
              <Route path="/uk/edit/:id" element={<Edit />} />
              <Route path="/uk/faq" element={<FaqPage />} />
              <Route path="/uk/about-us" element={<About />} />
              <Route path="/uk/contacts" element={<Contact />} />
              <Route path="/uk/privacy-policy" element={<Policy />} />
              <Route path="/uk/offer-contract" element={<OfferContract />} />
              <Route path="/uk/result/:orderId" element={<Result />} />

              {/* Маршруты для русского языка */}
              <Route path="/ru/" element={<Home />} />
              <Route path="/ru/blog" element={<Blog />} />
              <Route path="/ru/tags/:slug" element={<Blog />} />
              <Route path="/ru/posts/:slug" element={<FullPost />} />
              <Route path="/ru/posts/:slug/edit" element={<AddPost />} />
              <Route path="/ru/add-post" element={<AddPost />} />
              <Route path="/ru/products/:slug" element={<FullProduct />} />
              <Route path="/ru/products/:slug/edit" element={<AddProduct />} />
              <Route path="/ru/add-product" element={<AddProduct />} />
              <Route path="/ru/me" element={<Me />} />
              <Route path="/ru/login" element={<Login />} />
              <Route path="/ru/register" element={<Registration />} />
              <Route path="/ru/admin" element={<Admin />} />
              <Route path="/ru/forgot" element={<Forgot />} />
              <Route path="/ru/checkout" element={<Checkout />} />
              <Route path="/ru/orders/:id" element={<Orders />} />
              <Route path="/ru/order/:id" element={<Order />} />
              <Route path="/ru/edit/:id" element={<Edit />} />
              <Route path="/ru/faq" element={<FaqPage />} />
              <Route path="/ru/about-us" element={<About />} />
              <Route path="/ru/contacts" element={<Contact />} />
              <Route path="/ru/privacy-policy" element={<Policy />} />
              <Route path="/ru/offer-contract" element={<OfferContract />} />
              <Route path="/ru/result/:orderId" element={<Result />} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
