import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout, selectIsAdmin } from "../../redux/slices/auth";
import axios from "../../axios";
import { MyButton } from "../MyButton";
import { useTranslation } from "react-i18next";

export default function Navbar({ isHeader }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAdmin = useSelector(selectIsAdmin);

  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = window.localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }

        const userResponse = await axios.get(`/auth/me`);
        const userId = userResponse.data._id;

        const updatedMenu = {
          [t("navBar.myServices")]: `/orders/${userId}`,
          [t("navBar.edit")]: `/edit/${userId}`,
        };

        setMenu(updatedMenu);
      } catch (error) {
        console.error("Error fetching user data in navbar:", error);
      }
    };

    fetchData();
  }, [navigate, t]);

  const handleLogout = () => {
    if (window.confirm("Logout?")) {
      dispatch(logout());
      window.localStorage.removeItem("token");
      navigate("/");
    }
  };

  if (!menu) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={isAdmin ? `${styles.root} ${styles.none}` : `${styles.root}`}
    >
      <h2 className={styles.title}>{t("navBar.title")}</h2>
      <ul className={styles.list}>
        {Object.entries(menu).map(([name, path]) => (
          <li key={name} className={styles.item}>
            <a href={path}>{name}</a>
          </li>
        ))}
      </ul>
      <MyButton
        onClick={handleLogout}
        variant="darkGrey"
        className={styles.btn}
      >
        {t("common.exit")}
      </MyButton>
    </div>
  );
}
