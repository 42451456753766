import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MyButton } from "./MyButton";

export function NotFound({ payId }) {
  const { t } = useTranslation();
  return (
    <main
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h2 style={{ marginBottom: "40px", fontSize: "26px", fontWeight: "600" }}>
        {t("notFound.text")}
        {payId && (
          <>
            <br />
            Payment id : {payId}
          </>
        )}
      </h2>
      <Link to="/">
        <MyButton variant="dark">{t("notFound.link")}</MyButton>
      </Link>
    </main>
  );
}
