// src/components/OrderSkeleton.js

import React from "react";
import { Skeleton, Grid } from "@mui/material";

export const OrderSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {[1, 2, 3, 4, 5].map((_, index) => (
        <Grid item xs={12} key={index}>
          <Skeleton variant="rectangular" height={80} />
        </Grid>
      ))}
    </Grid>
  );
};
