import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { NotFound } from "../../components/NotFound";
import { Seccess } from "../../components/Seccess";

export function Result() {
  const { orderId } = useParams();

  const [payment, setPayment] = useState(null);
  const [payLink, setPayLink] = useState(null);

  const [isNotFullPay, setNotFullPay] = useState(false);
  const [isGoodPay, setGoodPay] = useState(false);

  useEffect(() => {
    const getPayLink = async () => {
      try {
        const response = await axios.get(`/whitepay/status/${orderId}`);
        setPayLink(response.data.order.id);
      } catch (error) {
        console.error("Error fetching crypto pay link:", error);
      }
    };

    const fetchPaymentStatus = async () => {
      try {
        const response = await axios.get(`/payment-status/${orderId}`);
        setPayment(response.data);

        if (response.data.status === "Part pay") {
          await getPayLink();
          setNotFullPay(true);
          setGoodPay(true);
        } else if (response.data.status === "Wait for access") {
          setNotFullPay(false);
          setGoodPay(true);
        } else {
          setGoodPay(false);
        }

        console.log("Pay status:", response.data);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };

    fetchPaymentStatus();
  }, [orderId]);

  return (
    <main>
      {isGoodPay && payment ? (
        <Seccess
          isNotFullPay={isNotFullPay}
          link={payLink}
          payId={payment.payId}
        />
      ) : (
        <NotFound payId={payment?.payId} />
      )}
    </main>
  );
}
