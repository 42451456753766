import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FaqItem } from "../FaqItem";
import { MyButton } from "../MyButton";
import styles from "./Faq.module.scss";

export default function Faq({ isFull }) {
  const { t } = useTranslation();

  const faqItems = t("faqs.items", { returnObjects: true });

  // Проверка и логирование
  if (!Array.isArray(faqItems)) {
    console.error("faqItems is not an array:", faqItems);
    return <p>Error loading FAQ items</p>;
  }

  return (
    <section className={isFull ? styles.root : `${styles.root} ${"section"}`}>
      <h2 className="title">FAQs</h2>
      {faqItems.slice(0, isFull ? faqItems.length : 5).map((item, index) => (
        <FaqItem key={index} title={item.title} text={item.text} />
      ))}
      {!isFull && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/faq">
            <MyButton variant="dark">{t("faqs.more")}</MyButton>
          </Link>
        </div>
      )}
    </section>
  );
}
