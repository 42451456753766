import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import { Post } from "../../components/Post";
import { TagsBlock } from "../../components/TagsBlock";
import { fetchPosts, fetchTags } from "../../redux/slices/post";
import MetaTags from "../../components/MetaTags";
import styles from "./Blog.module.scss";

export const Blog = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(fetchPosts());
    dispatch(fetchTags());
  }, [dispatch]);

  const posts = useSelector((state) => state.posts.posts);
  const tags = useSelector((state) => state.posts.tags);
  const userData = useSelector((state) => state.auth.data);

  const isPostLoading = !posts || posts.status === "loading";
  const isTagsLoading = tags.status === "loading";
  const isError = posts.status === "error";

  const isFiltring = Boolean(slug);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    if (slug) {
      axios
        .get(`/tags/${slug}`)
        .then((response) => {
          setFilteredPosts(response.data);
        })
        .catch((err) => {
          console.error(err);
          alert("Cannot filter by tags");
        });
    }
  }, [slug]);

  return (
    <main>
      <MetaTags
        title={slug ? `${slug}` : t("metaTagsConfig.blog.title")}
        description={
          slug ? `This ${slug} page` : t("metaTagsConfig.blog.description")
        }
      />
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          {t("blog.home")}
        </Link>
        <Link underline="hover" color="inherit" href="/blog">
          <h1>{t("blog.blog")}</h1>
        </Link>
      </Breadcrumbs>
      <div className={styles.root}>
        <div className={styles.tags}>
          <TagsBlock params={slug} />
        </div>
        <div className={styles.lists}>
          {isPostLoading ? (
            <p className={styles.error}>Loading...</p>
          ) : posts && posts.items.length > 0 ? (
            isFiltring ? (
              filteredPosts.map((obj, index) => (
                <Post
                  key={index}
                  _id={obj._id}
                  title={obj.title[i18n.language]}
                  imageUrl={
                    obj.imageUrl
                      ? `${process.env.REACT_APP_API_URL}${obj.imageUrl}`
                      : ""
                  }
                  user={obj.user}
                  createdAt={obj.createdAt}
                  viewsCount={obj.viewsCount}
                  tags={obj.tags[i18n.language]}
                  isEditable={userData?._id === obj.user._id}
                  slug={obj.slug}
                />
              ))
            ) : (
              posts.items.map((obj, index) => (
                <Post
                  key={index}
                  _id={obj._id}
                  title={obj.title[i18n.language]}
                  imageUrl={
                    obj.imageUrl
                      ? `${process.env.REACT_APP_API_URL}${obj.imageUrl}`
                      : ""
                  }
                  user={obj.user}
                  createdAt={obj.createdAt}
                  viewsCount={obj.viewsCount}
                  tags={obj.tags[i18n.language]}
                  isEditable={userData?._id === obj.user._id}
                  slug={obj.slug}
                />
              ))
            )
          ) : (
            <p className={styles.error}>The posts is empty</p>
          )}
          {isError && <p className={styles.error}>Failed to load posts.</p>}
        </div>
      </div>
    </main>
  );
};
