import React from "react";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

import styles from "./Product.module.scss";
import { MyButton } from "../MyButton";
import { ProductSkeleton } from "./Skeleton";
import { fetchRemoveProduct } from "../../redux/slices/product";

export default function ProductItem({
  _id,
  title,
  imageUrl,
  options,
  price,
  squ,
  isEditable,
  isLoading,
  isSmall,
  slug,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickRemove = () => {
    if (window.confirm("Remove?")) {
      dispatch(fetchRemoveProduct(slug));
    }
  };

  if (isLoading) {
    return <ProductSkeleton />;
  }

  return (
    <div className={styles.root}>
      {isEditable && (
        <div className={styles.editButtons}>
          <Link to={`/products/${slug}/edit`}>
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton onClick={onClickRemove} color="secondary">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      <div className={styles.wrapper}>
        <div
          className={styles.content}
          style={
            isSmall ? { background: "#D3FF4E" } : { background: "#f6ecfe" }
          }
        >
          <h2 className={clsx(styles.title)}>{title}</h2>
          <p className={clsx(styles.squ)}>{squ}</p>
        </div>
        {isSmall ? (
          <></>
        ) : (
          <p className={clsx(styles.price)}>
            <span>${price}</span> {t("productItem.perMounth")}
          </p>
        )}

        {imageUrl && (
          <img className={clsx(styles.image)} src={imageUrl} alt={title} />
        )}
        {isSmall ? (
          <></>
        ) : (
          <ReactMarkdown children={options} className={clsx(styles.text)} />
        )}
        <Link to={`/products/${slug}`} className={clsx(styles.link)}>
          <MyButton variant={isSmall ? "text" : "dark"} fullWidth>
            {t("common.selectMac")}
          </MyButton>
        </Link>
      </div>
    </div>
  );
}
