import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import { MyButton } from "../../components/MyButton";
import { selectIsAuth } from "../../redux/slices/auth";
import styles from "../Login/Login.module.scss";

export const Forgot = () => {
  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resetCode, setResetCode] = useState(null);
  const [isTwoStep, setIsTwoStep] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const onSubmit = async (values) => {
    const { email } = values;

    try {
      const response = await axios.post("/auth/reset", { email });

      alert(response.data.message);

      if (response.data.success) {
        setIsTwoStep(true);
      }
    } catch (error) {
      console.error("An error occurred while resetting password:", error);
      alert("An error occurred while resetting password. Please try again.");
    }
  };

  const onReset = async (values) => {
    const { newPassword, confirmNewPassword } = values;

    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await axios.post("/auth/reset/confirm", {
        resetCode,
        newPassword,
      });

      alert(response.data.message);
      navigate("/");

      if (response.data.success) {
        setIsTwoStep(false);
      }
    } catch (error) {
      console.error("An error occurred while resetting password:", error);
      alert("An error occurred while resetting password. Please try again.");
    }
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <main className={styles.login}>
      <Breadcrumbs className="bred" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          {t("common.home")}
        </Link>
        <Typography color="text.primary">{t("forgot.title")}</Typography>
      </Breadcrumbs>
      <div className={styles.root}>
        <h2 className={`${styles.title} title`}>{t("forgot.title")}</h2>
        <h3 className={`${styles.subtitle} subtitle`}>{t("forgot.text")}</h3>

        {isTwoStep ? (
          <form className={styles.form} onSubmit={handleSubmit(onReset)}>
            <TextField
              type="text"
              className={styles.field}
              label="Reset code"
              onChange={(e) => setResetCode(e.target.value)}
              fullWidth
              value={resetCode || ""}
            />
            <TextField
              type="password"
              className={styles.field}
              label="New password"
              error={Boolean(errors.newPassword?.message)}
              helperText={errors.newPassword?.message}
              {...register("newPassword", {
                required: "Enter new password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              fullWidth
            />
            <TextField
              type="password"
              className={styles.field}
              label="Confirm new password"
              error={Boolean(errors.confirmNewPassword?.message)}
              helperText={errors.confirmNewPassword?.message}
              {...register("confirmNewPassword", {
                required: "Confirm new password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              fullWidth
            />
            <MyButton type="submit" size="large" variant="contained" fullWidth>
              {t("forgot.editPassword")}
            </MyButton>
          </form>
        ) : (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              type="email"
              className={styles.field}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
              {...register("email", { required: t("registration.enterEmail") })}
              label="Email"
              fullWidth
            />
            <MyButton type="submit" size="large" variant="contained" fullWidth>
              {t("forgot.title")}
            </MyButton>
          </form>
        )}
        <p className={styles.text}>{t("forgot.supported")}</p>
      </div>
    </main>
  );
};
