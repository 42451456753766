import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import { selectIsAdmin } from "../../redux/slices/auth";
import { MyButton } from "../../components/MyButton";
import styles from "../Me/Me.module.scss";
import Navbar from "../../components/Navbar";
import icon from "../../assets/images/acount-icon.svg";

export const Edit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await axios.get(`/auth/me`);
        const currentUserId = userResponse.data._id;

        if (id !== currentUserId) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (window.localStorage.getItem("token")) {
      fetchUser();
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
    },
  });

  const onSubmit = async () => {
    try {
      const { password, newPassword } = getValues();
      const fields = { password, newPassword };
      const response = await axios.patch(`/user/edit/${id}`, fields);

      if (response.status === 200) {
        alert("User successfully updated!");
      } else {
        alert("Error occurred while updating user!");
      }
    } catch (error) {
      console.error("Failed edit user:", error);
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data)
      ) {
        const errors = error.response.data;
        let errorMessage = "An error occurred. \n";
        errors.forEach((error) => {
          errorMessage += `${error.param}: ${error.msg}\n`;
        });
        alert(errorMessage);
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  };

  return (
    <main>
      <div className={styles.wrapp}>
        {isAdmin ? (
          <></>
        ) : (
          <div className={`${styles.item} ${styles.navbar}`}>
            <Navbar />
          </div>
        )}
        <div className={styles.item}>
          {loading ? (
            <p>Loading page...</p>
          ) : (
            <>
              <h2 className={styles.title}>
                <img src={icon} alt="" className={styles.icon} />{" "}
                {t("edit.title")}
              </h2>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <p className={styles.text}>{t("edit.change")} </p>
                <TextField
                  type="password"
                  className={styles.field}
                  label={t("edit.curPas")}
                  error={Boolean(errors.password?.message)}
                  helperText={errors.password?.message}
                  {...register("password", {
                    required: t("edit.enterCurPas"),
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                  fullWidth
                />
                <TextField
                  type="password"
                  className={styles.field}
                  label={t("edit.newPas")}
                  error={Boolean(errors.newPassword?.message)}
                  helperText={errors.newPassword?.message}
                  {...register("newPassword", {
                    required: t("edit.enterNewPas"),
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                  fullWidth
                />
                <MyButton type="submit" variant="dark">
                  {t("edit.save")}
                </MyButton>
              </form>
            </>
          )}
        </div>
      </div>
    </main>
  );
};
