import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Benefits.module.scss";
import image from "../../assets/images/benefits-img.png";
import logo from "../../assets/images/benefits-logo.svg";

export default function Benefits() {
  const { t } = useTranslation();
  return (
    <section className={`${styles.root} ${"section"}`}>
      <h2 className="title">{t("benefits.title")}</h2>
      <div className={styles.wrapp}>
        <div className={styles.item}>
          <h3 className={styles.title}>{t("benefits.subtitle1")}</h3>
          <p className={styles.text}>{t("benefits.text1")}</p>
          <img src={image} className={styles.image} alt="" />
        </div>
        <div className={styles.item}>
          <div className={styles.top}>
            <div className={styles.small}>
              <h3 className={styles.subtitle}>{t("benefits.subtitle2")}</h3>
              <p className={styles.text}>{t("benefits.text2")}</p>
            </div>
            <div className={styles.small}>
              <h3 className={styles.subtitle}>{t("benefits.subtitle3")}</h3>
              <p className={styles.text}>{t("benefits.text3")}</p>
            </div>
          </div>
          <div className={styles.bottom}>
            <h3 className={styles.subtitle}>{t("benefits.subtitle4")}</h3>
            <p className={styles.text}>{t("benefits.text4")}</p>
            <img src={logo} className={styles.logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
