import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { fetchOrder } from "../../redux/slices/order";
import axios from "../../axios";
import MetaTags from "../MetaTags";
import { MyButton } from "../MyButton";

import styles from "./Product.module.scss";
import { ProductSkeleton } from "./Skeleton";
import { useTranslation } from "react-i18next";

export default function Product() {
  const { t, i18n } = useTranslation();

  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [leasePeriod, setLeasePeriod] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = useCallback((value) => {
    setLeasePeriod(value);
  }, []);

  useEffect(() => {
    axios
      .get(`/products/${slug}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => {
        // alert("Продукт не найден");
        console.log(err);
      });
    onChange(1);
  }, [slug, i18n.language]);

  if (!product) {
    return <ProductSkeleton />;
  }

  const onSubmit = async () => {
    try {
      if (!leasePeriod) {
        alert("Select product variant");
        return;
      }

      const fields = {
        productId: product._id,
        leasePeriod,
      };

      dispatch(fetchOrder(fields));

      if (fields) {
        navigate(`/checkout`);
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      console.log(error);
      alert("Orders error");
    }
  };

  return (
    <section>
      <MetaTags
        title={product.metaTitle[i18n.language]}
        description={`This ${product.metaDescription[i18n.language]} product`}
      />
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Typography color="text.primary">{product.title}</Typography>
      </Breadcrumbs>

      <div className={styles.wrapp}>
        <div className={styles.item}>
          <div className={`${"greenBg"} ${styles.greenBg}`}>
            {t("common.notWirt")}
          </div>
          <img
            src={`${process.env.REACT_APP_API_URL}${
              product.imageUrl
                ? product.imageUrl
                : "/assets/images/none-product.png"
            }`}
            alt={product.title}
            className={styles.image}
          />
        </div>
        <div className={styles.item}>
          <h1 className={styles.title}>{product.title}</h1>
          <p className={styles.squ}> {product.squ}</p>
          {/* <p className={styles.squ}>Количество: {product.quantity}</p> */}
          <p className={styles.price}>
            <span>${product.price * leasePeriod} </span>
            {t("productItem.perMounth")}
            {i18n.language === "uk" ? (
              <> ( {product.price * leasePeriod * 42}грн )</>
            ) : (
              <></>
            )}
          </p>
          <ReactMarkdown children={product.options} className={styles.option} />

          <div className={styles.buttonGroup}>
            <button
              onClick={() => onChange(1)}
              className={
                leasePeriod === 1
                  ? `${styles.btn} ${styles.active}`
                  : `${styles.btn}`
              }
            >
              1 {t("product.month")}.
            </button>
            <button
              onClick={() => onChange(3)}
              className={
                leasePeriod === 3
                  ? `${styles.btn} ${styles.active}`
                  : `${styles.btn}`
              }
            >
              3 {t("product.month")}.
            </button>
            <button
              onClick={() => onChange(6)}
              className={
                leasePeriod === 6
                  ? `${styles.btn} ${styles.active}`
                  : `${styles.btn}`
              }
            >
              6 {t("product.month")}.
            </button>
            <button
              onClick={() => onChange(12)}
              className={
                leasePeriod === 12
                  ? `${styles.btn} ${styles.active}`
                  : `${styles.btn}`
              }
            >
              12 {t("product.month")}.
            </button>
          </div>

          <p className={styles.description}>{product.text[i18n.language]}</p>

          <div className={styles.btns}>
            {product.quantity !== 0 ? (
              <MyButton
                onClick={() => {
                  if (window.localStorage.getItem("token")) {
                    onSubmit();
                  } else {
                    navigate("/login");
                  }
                }}
                variant="dark"
                className={styles.customBtn}
              >
                {t("product.select")}
              </MyButton>
            ) : (
              <></>
            )}

            <MyButton variant="grey" className={styles.customBtn}>
              <a href="#form">{t("product.questions")}</a>
            </MyButton>
          </div>
          {product.quantity === 0 ? <p>{t("product.notProduct")}</p> : <></>}
        </div>
      </div>
    </section>
  );
}
