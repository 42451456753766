import React, { useEffect, useState } from "react";
import styles from "./Admin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../redux/slices/user";
import { selectIsAdmin } from "../../redux/slices/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "../../axios";
import PropTypes from "prop-types";

import { AdminSkeleton } from "./Skeleton";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin);
  const [value, setValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [selectValue, setSelectValue] = useState("All");
  const [orderStatuses, setOrderStatuses] = useState(["All"]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/");
      return;
    } else {
      dispatch(fetchUsers());
    }

    axios
      .get(`/orders`)
      .then((res) => {
        setOrders(res.data);
        const uniqueStatuses = [
          "All",
          ...new Set(res.data.map((order) => order.status)),
        ];
        setOrderStatuses(uniqueStatuses);
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(`/auth/me`).then((res) => {
      setAdminId(res.data._id);
      setLoading(false);
    });
  }, [dispatch, isAdmin, navigate]);

  useEffect(() => {
    if (!loading && isAdmin === false) {
      navigate("/");
    }
  }, [loading, isAdmin, navigate]);

  const users = useSelector((state) => state.users.users);
  const isUserLoading = users.status === "loading";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const filteredOrders =
    selectValue === "All"
      ? orders
      : orders.filter((order) => order.status === selectValue);

  if (isUserLoading) {
    return <AdminSkeleton />;
  }

  function getMonthDifference(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const minutesDifference = Math.ceil(diffInMilliseconds / (1000 * 60));
    return minutesDifference;
  }

  return (
    <main className={styles.root}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
        }}
        className={styles.tabWrap}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="admin tabs"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="ORDERS" {...a11yProps(0)} />
          <Tab label="USERS" {...a11yProps(1)} />
        </Tabs>
        <Link to={`/edit/${adminId}`} style={{ textDecoration: "none" }}>
          <Button variant="outlined" sx={{ marginLeft: 2 }}>
            Редагувати профіль
          </Button>
        </Link>
        <Link to="/add-post" style={{ textDecoration: "none" }}>
          <Button variant="outlined" sx={{ marginLeft: 2 }}>
            Створити пост
          </Button>
        </Link>
        <Link to="/add-product" style={{ textDecoration: "none" }}>
          <Button variant="outlined" sx={{ marginLeft: 2 }}>
            Додати продукт
          </Button>
        </Link>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <h1 className={styles.title}>Orders</h1>
        <Select
          value={selectValue}
          onChange={handleSelectChange}
          className={styles.select}
        >
          {orderStatuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
        {filteredOrders.length === 0 ? (
          <p>No orders</p>
        ) : (
          <>
            <TableContainer component={Paper} className={styles.tabContent}>
              <Table sx={{ minWidth: 650 }} aria-label="order table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id order</TableCell>
                    <TableCell>Name product</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Availability date</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Order status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders.map((order) => (
                    <TableRow
                      key={order._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{order._id}</TableCell>
                      <TableCell>{order.product.title}</TableCell>
                      <TableCell>{order.user.email}</TableCell>
                      <TableCell>
                        {new Date(order.availableTo).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {order.product.price *
                          getMonthDifference(
                            new Date(order.createdAt),
                            new Date(order.availableTo)
                          )}
                        $
                      </TableCell>
                      <TableCell
                        style={
                          order.status === "Success"
                            ? { color: "#00B528" }
                            : order.status === "Part pay"
                            ? { color: "#edff4b" }
                            : order.status === "Wait for access"
                            ? { color: "#7400D1" }
                            : order.status === "Finished"
                            ? { color: "#F10E00" }
                            : {}
                        }
                      >
                        {order.status}
                      </TableCell>

                      <TableCell>
                        <Link to={`/order/${order._id}`}>
                          <Button variant="contained">Edit</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <h1 className={styles.title}>Users</h1>
        {users.length === 0 ? (
          <p>No users</p>
        ) : (
          <TableContainer component={Paper} className={styles.tabContent}>
            <Table sx={{ minWidth: 650 }} aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Links</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.items.map((user) => (
                  <TableRow
                    key={user._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{user._id}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Link to={`/orders/${user._id}`}>
                        <Button size="large" variant="contained">
                          View all
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CustomTabPanel>
    </main>
  );
};
