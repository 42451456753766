import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags } from "../../redux/slices/post";

import styles from "./TagsBlock.module.scss";
import img from "../../assets/images/catalog-filter-img.svg";
import MetaTags from "../MetaTags";

export const TagsBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [activeIndex, setActiveIndex] = useState("");

  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  const { items, status } = useSelector((state) => state.posts.tags);
  const slugs = items.slugTag;
  const isLoading = status === "loading";

  // Определяем переменную isTagsPage
  const isTagsPage = !isLoading && slugs.includes(slug);

  useEffect(() => {
    if (isTagsPage) {
      const index = slugs.indexOf(slug);
      setActiveIndex(index);
    }
  }, [isLoading, items, slug, isTagsPage, slugs]);

  return (
    <div className={styles.root}>
      {!isLoading && isTagsPage && (
        <MetaTags
          title={
            slug
              ? items[i18n.language][activeIndex]
              : t("metaTagsConfig.blog.title")
          }
          description={
            slug ? `This ${slug} page` : t("metaTagsConfig.blog.description")
          }
        />
      )}

      <Link to="/blog" className={styles.btn}>
        {t("tagsBlock.title")}
        <img src={img} alt="" className={styles.img} />
      </Link>
      <ul className={styles.list}>
        {(isLoading
          ? Array.from({ length: 5 })
          : items[i18n.language] || []
        ).map((name, i) => (
          <Link
            key={i}
            to={!isLoading ? `/tags/${slugs[i]}` : "/"}
            className={`${i !== activeIndex ? "" : styles.active} ${
              styles.link
            }`}
          >
            {isLoading ? <Skeleton width={100} /> : <p>{name}</p>}
          </Link>
        ))}
      </ul>
    </div>
  );
};
