import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Instruction.module.scss";

export default function Instruction() {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>{t("instruction.title")}</h4>
      <ul className={styles.content}>
        <li className={styles.item}>
          <span className={styles.number}>1</span>
          <p className={styles.text}>
            {t("instruction.text1")}
            <a
              href="https://support.anydesk.com/knowledge/connecting-to-a-remote-client"
              className={styles.link}
            >
              anydesk.com
            </a>
          </p>
        </li>
        <li className={styles.item}>
          <span className={styles.number}>2</span>
          <p className={styles.text}> {t("instruction.text2")}</p>
        </li>
        <li className={styles.item}>
          <span className={styles.number}>3</span>
          <p className={styles.text}> {t("instruction.text3")}</p>
        </li>
      </ul>
    </div>
  );
}
