import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "../Login/Login.module.scss";
import { MyButton } from "../../components/MyButton";
import { fetchRegister, selectIsAuth } from "../../redux/slices/auth";
import MetaTags from "../../components/MetaTags";

export const Registration = () => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (values) => {
    try {
      const data = await dispatch(fetchRegister(values));
      if (!data.payload) {
        throw new Error("Registration failed");
      }
      if ("token" in data.payload) {
        window.localStorage.setItem("token", data.payload.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuth) {
    return <Navigate to="/me" />;
  }

  return (
    <main className={styles.login}>
      <MetaTags
        title={t("metaTagsConfig.registration.title")}
        description={t("metaTagsConfig.registration.description")}
      />
      <div className={styles.root}>
        <Breadcrumbs className="bred" aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            {t("common.home")}
          </Link>
          <Typography color="text.primary">
            {" "}
            {t("registration.title")}
          </Typography>
        </Breadcrumbs>

        <h2 className={`${styles.title} title`}>{t("registration.title")}</h2>
        <h3 className={`${styles.subtitle} subtitle`}>
          {t("registration.text")}
        </h3>
        <p className={styles.text}>
          {t("registration.haveAc")}{" "}
          <RouterLink to="/login">{t("registration.enter")}</RouterLink>
        </p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={styles.field}
            label="E-Mail"
            type="email"
            fullWidth
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            {...register("email", {
              required: t("registration.enterEmail"),
              pattern: {
                value: /^[^@]+@[^@]+\.[^@]+$/,
                message: t("registration.enterEmail"),
              },
            })}
          />
          <TextField
            className={styles.field}
            label={t("registration.password")}
            type="password"
            fullWidth
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            {...register("password", {
              required: t("registration.enterPassword"),
              minLength: {
                value: 6,
                message: t("registration.minLength"),
              },
            })}
          />
          <MyButton type="submit" variant="dark" disabled={!isValid}>
            {t("registration.reg")}
          </MyButton>
        </form>
        <p className={styles.text}>{t("registration.dontSave")}</p>
      </div>
    </main>
  );
};
